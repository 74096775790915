import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import moment from 'moment'
import store from './store'
import PrimeVue from 'primevue/config'
import "bootstrap/dist/css/bootstrap.min.css"

createApp(App).use(store).use(router).use(PrimeVue).mixin({
    methods: {
        formatDate (value: string, type: string) {
            const definedMoment = moment(value).locale('nl')
            if (type == 'dateTime') {
                return definedMoment.format('DD-MM-YYYY HH:mm:ss')
            } else if (type == 'fromNow') {
                return definedMoment.fromNow()
            } else if (type == 'isoDate') {
                return definedMoment.format('YYYY-MM-DD')
            } else if (type == 'shortDate') {
                return definedMoment.format('DD-MM-YYYY')
            } else if (type == 'longDate') {
                return definedMoment.format('DD MMMM YYYY')
            } else if (type == 'tinyDate') {
                return definedMoment.format('DD-MM')
            } else if (type == 'time') {
                return definedMoment.format('HH:mm')
            } else if (type == 'todayTime') {
                if(definedMoment.isSame(new Date(), "day")) {
                    return definedMoment.format('HH:mm')
                }
                return definedMoment.format('DD-MM-YYYY HH:mm:ss')
            }

            return definedMoment.format('YYYY-MM-DD')
        }
    }
}).mount('#app')
